import {h} from 'preact'

const Header = ({url, token, me}) => (
  <header className="header">
    {token ? (
      <nav>
        <a className={(url === '/' || url.startsWith('/work')) ? 'active' : ''} href="/work">
          Work
        </a>
        <a className={url.startsWith('/leads') ? 'active' : ''} href="/leads">
          Leads
        </a>
        <a className={url.startsWith('/offers') ? 'active' : ''} href="/offers">
          Offers
        </a>
        <a className={url.startsWith('/transfers') ? 'active' : ''} href="/transfers">
          Transfers
        </a>
        <a className={url.startsWith('/orgs') ? 'active' : ''} href="/orgs">
          ORGs
        </a>
        <a className={url.startsWith('/nets') ? 'active' : ''} href="/nets">
          Networks
        </a>
        <a className={url.startsWith('/requests') ? 'active' : ''} href="/requests">
          Requests
        </a>
        <a className={url.startsWith('/invoices') ? 'active' : ''} href="/invoices">
          Invoices
        </a>
        <a className={url.startsWith('/whois') ? 'active' : ''} href="/whois">
          Whois
        </a>
        <a className={url.startsWith('/bl') ? 'active' : ''} href="/bl">
          BL
        </a>
        <a className={url.startsWith('/ta') ? 'active' : ''} href="/ta">
          TA
        </a>
        <a className={url.startsWith('/rent') ? 'active' : ''} href="/rent">
          Rent
        </a>
        <a className={url.startsWith('/memo') ? 'active' : ''} href="/memo">
          Memo
        </a>
        <a className={url.startsWith('/profile') ? 'active float-right' : 'float-right'} href={`/profile/${me.nick}`}>
          {me.nick}
        </a>
      </nav>
    ) : (
      <nav>
        <a className="active" href="/">Login</a>
      </nav>
    )}
  </header>
)

export default Header
